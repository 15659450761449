import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import ProudCoderSvg from "../images/undraw/undraw_proud_coder_7ain.svg";
import LogoCloud from "../components/LogoCloud";

const RubyOnRailsNewPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
            previousUrl="/ruby-on-rails"
            nextUrl="/ruby-on-rails-new-2"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Here are some of my key considerations and insights for why you
              might want to have your next application developed in Ruby on
              Rails:
            </p>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faCube}
                  size="2x"
                  className="text-magenta-900"
                />
                <p className="ml-5 text-base leading-6 text-gray-700 text-left mb-3">
                  for <strong>small to medium-sized</strong> teams, I’ve made
                  the experience that a <strong>“monolithic”</strong>{" "}
                  application architecture leads to a higher development
                  velocity, <strong>more cohesive</strong> codebase, and less
                  code duplication than a strictly divided frontend/backend
                  architecture
                </p>
              </div>
              <figure className="w-full">
                <img alt="proud coder" src={ProudCoderSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "weavs",
                url: "https://www.weavs.io",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsNewPage;
